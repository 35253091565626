<template>
  <div class="AIText">
    <div class="tit">一切就绪，现在就开始让AI和你一起创作吧！</div>
    <div class="blackboard" ref="bigRef">
      <div class="messageBox" ref="Ref">
        <div v-for="item in messages" :key="item.text" class="message">
          <div v-if="item.isMe" class="messageMe">
            <p>{{ item.text }}</p>
            <div class="nameBox">
              <div class="name">我</div>
              <el-avatar
                shape="square"
                size="small"
                :src="require('@/components/Header/images/userImg.png')"
              ></el-avatar>
            </div>
          </div>
          <div v-else class="messageAi">
            <div class="nameBox">
              <el-avatar
                shape="square"
                size="small"
                :src="require('@/assets/images/aiImg.png')"
              ></el-avatar>
              <div class="name">AI创作助手</div>
            </div>

            <div class="AIstory" @click="onDialog(item.text)">
              <span> {{ item.text }}</span>
              <div class="button" v-show="item.buttonShow">
                <img
                  src="@/assets/images/select.png"
                  style="width: 18px"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inputBox" v-if="!loading">
      <el-input
        v-model="question"
        @keyup.enter.native="submit"
        placeholder="请输入对话内容"
      >
      </el-input>
      <div class="butImg" @click="submit">
        <img src="@/assets/images/aircraft.png" alt="" />
      </div>
    </div>
    <div v-else class="inputBox">
      <div class="loadingBox">
        <div>
          <span class="el-icon-loading" />
          AI正在为您进行创作，请等待...
        </div>
      </div>
    </div>
    <TextBox
      :width="1000"
      :height="670"
      okText="选用文本，开始配图"
      @close="onClose"
      @ok="onConfirm"
      :top="200"
      :visible="visible"
    >
      <div class="textBoxLabel"><span>*</span> 请为您的作品命名：</div>
      <div class="goTextBox">
        <el-input
          style="width: 660px"
          v-model="works.input"
          placeholder="请输入作品名称，不超过50字"
        ></el-input>
        <div class="error">
          {{works.error}}
        </div>
      </div>

      <div class="textBoxLabel">请编辑并确认您要发布的作品文本：</div>
      <el-input type="textarea" :rows="16" v-model="works.textarea"> </el-input>
    </TextBox>
  </div>
</template>

<script>
import { chat, chatRecord } from "@/api/nft";
import TextBox from "@/components/textBox";
import { mapState } from "vuex";
export default {
  name: "Create",
  components: {
    TextBox,
  },
  data() {
    return {
      loading: false,
      messages: [{ text: "你好", isMe: false, name: "AI创作助手" }],
      question: "",
      visible: false,
      selectedText: "",
      dialogInput: "",
      works: {
        input: "",
        textarea: "",
        error:''
      },
    };
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    onHover(obj) {
      obj.buttonShow = true;
    },
    onDialog(content) {
      // 调用Dialog弹出对话框
      this.works.textarea = content;
      this.visible = true;
    },
    onClose() {
      // 关闭dialog
      this.visible = false;
    },
    onConfirm() {
      if(this.works.input==''){
        this.works.error='请输入作品名称' 
        return 
      }
      if(this.works.input.length>=50){
        this.works.error='您输入的作品名称过长，请重新输入' 
        return 
      }
      this.$store.commit("ARTICLE", {
        title: this.works.input,
        description: this.works.textarea,
      });
      this.$router.push({
        path: "/AIImg",
        query: {
          type: this.$route.query.type,
        },
      });
    },
    getHistory() {
      let param = {
        chatId: this.accountId + "_" + this.agoId,
        pageSize: 10,
        page: 1,
      };
      chatRecord(param).then(({ data, code }) => {
        if (code == 1000) {
          this.messages = data;
          this.messages.push({
            text: "请告诉我您的构思，我来为您创作！",
            isMe: false,
          });
        }
      });
    },

    submit() {
      this.loading = true;
      let param = {
        articleId: this.agoId, //	文章id
        accountId: this.accountId,
        chatId: this.accountId + "_" + this.agoId,
        question: this.question,
      };
      this.messages.push({
        text: this.question,
        isMe: true,
      });
      this.$nextTick(() => {
        this.$refs.bigRef.scrollTop = this.$refs.Ref.offsetHeight;
      });
      this.question = "";
      chat(param).then(({ data, code }) => {
        if (code == 1000) {
          this.messages.push({
            text: data,
            isMe: false,
            buttonShow: true,
          });
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.bigRef.scrollTop = this.$refs.Ref.offsetHeight;
          });
        }
      });
    },
  },
  computed: {
    ...mapState({
      accountId: (state) => state.user.accountId,
      agoId: (state) => state.page.agoId,
    }),
  },
};
</script>

<style lang="less" scoped>
.AIText {
  padding-top: 10vh;
  padding-bottom: 10vh;
  height: 70vh;
  //滚动条美化
  .blackboard::-webkit-scrollbar {
    width: 6px;
  }
  .blackboard::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(249, 249, 249, 0.2);
  }
  //滚动条隐藏
  .blackboard::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(249, 249, 249, 0.1);
  }
  .tit {
    margin: 8px 0;
    background: linear-gradient(
      0deg,
      rgba(1, 227, 155, 0.3) -3%,
      rgba(247, 239, 19, 0.3) 96%
    );
    line-height: 32px;
    text-align: center;
    border-radius: 16px;
  }
  .blackboard {
    height: 82%;
    width: 100%;
    overflow-y: auto;
    background: #f2f2f227;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;

    .messageBox {
      padding: 20px;
      .messageMe {
        position: relative;
        display: flex;
        justify-content: end;
        .name {
          position: absolute;
          top: 0;
          right: 35px;
          color: #828282;
        }
        p {
          width: 90%;
          text-align: right;
          box-sizing: border-box;
          padding-right: 20px;
          padding-top: 20px;
          color: #01e39b;
        }
      }
      .messageAi {
        position: relative;
        display: flex;
        .name {
          position: absolute;
          left: 35px;
          top: 0;
          color: #828282;
        }
        .AIstory {
          width: 90%;
          line-height: 24px;
          padding-left: 20px;
          padding-top: 20px;
          box-sizing: border-box;
          cursor: pointer;
          position: relative;
          .button {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            left: -35px;
            top: 70%;
            width: 30px;
            height: 17px;

            border-radius: 10px;
            color: #828282;
            background-color: #f4ea29;
          }
        }

        .AIstory:hover {
          background-color: rgba(255, 255, 0, 0.193);
        }
      }
    }
  }
  .inputBox {
    width: 100%;
    height: 100px;
    background: #f2f2f227;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 16px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /deep/ .el-input {
      height: 100%;
      border: none;
      box-sizing: border-box;
      .el-input__inner {
        box-sizing: border-box;
        height: 100%;
        padding-right: 100px;
        display: block;
        background: rgba(0, 0, 0, 0.6);
        border: none;
        border-radius: 8px;
      }
    }
    .loadingBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.5);
      color: #01e39b;
      font-size: 14px;
    }
    .butImg {
      position: absolute;
      cursor: pointer;
      width: 80px;
      height: 60px;
      right: 10px;
      top: 26px;
    }
  }
  .textBoxLabel {
    line-height: 30px;
    font-size: 12px;
    color: #01e39b;
    span {
      color: #d90000;
      font-weight: 600;
    }
  }
  .goTextBox {
    display: flex;
    align-items: center;
    .error{
      margin-left: 30px;
      font-size: 12px;
      color: #AF644C;
    }
  }
  /deep/ .el-input {
    height: 48px;
    border: none;
    box-sizing: border-box;
    .el-input__inner {
      height: 38px;
      display: block;
      background: rgba(0, 0, 0, 0.7);
      border: 1px solid #8e8e8e;
    }
  }
  /deep/ .el-textarea {
    border: none;
    box-sizing: border-box;

    .el-textarea__inner {
      display: block;
      background: rgba(0, 0, 0, 0.7);
      border: none;
    }

    .el-textarea__inner::-webkit-scrollbar {
      width: 6px;
    }
    .el-textarea__inner::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(249, 249, 249, 0.2);
    }
    .el-textarea__inner::-webkit-scrollbar-track {
      border-radius: 0;
      background: rgba(249, 249, 249, 0.1);
    }
  }
}
</style>
